<template>
  <div class="my_inquiry">
       <div class="title">采购询价</div>
       <div class="wrap2 flex-col" @click="init_inquiry">
            <span class="word3">发布询盘</span>
       </div>
       <div class="box22">
            <div class="box33">
                 <el-table :data="inquiryList" style="width: 100%">
                      <el-table-column prop="index" align="center" type="index"/>
                      <el-table-column prop="enterpriseName" label="单位名称" width="150" align="center" />
                      <el-table-column prop="receiverName" label="姓名" width="130" align="center" />
                      <el-table-column prop="receiverMobile" label="联系电话" width="130" align="center" />
                      <el-table-column prop="description" label="需求内容" width="220" align="center" />
                      <el-table-column label="需求单" width="160" align="center">
                           <template #default="scope">
                                <div class="demand_list" v-if="scope.row.referenceFile">
                                  <img src="@/assets/file_excel.png" alt="">
                                  <p>{{ scope.row.title }}</p>
                                </div>
                                <div v-else>
                                   --
                                </div>
                           </template>
                      </el-table-column>
                      <el-table-column prop="createTime" label="提交时间" width="180" align="center" />
                      <el-table-column label="操作" width="180" align="center">
                           <template #default="scope">
                                <div class="TextGroup1 flex_column_center_center">
                                     <div class="flex-row">
                                          <span class="txt77" @click="toxia(scope.row.referenceFile)">下载需求单</span>
                                     </div>
                                     <div class="main3">
                                          <span class="word15"
                                               @click="toDelete(scope.row.inquireId)">删除</span>
                                     </div>
                                </div>
                           </template>
                      </el-table-column>
                 </el-table>
            </div>


            <!-- 分页 -->
            <div class="flex_row_center_center sld_pagination">
                 <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                      :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                      :hide-on-single-page="true">
                 </el-pagination>
            </div>
       </div>

       <!-- <div class="inquiry_empty flex_column_center_center" v-else>
            <img src="@/assets/inquiry/inq_empty.png" alt="">
            <p>暂无报价</p>
       </div> -->
  </div>
</template>

<script>
  import { ref, reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
  import { inqUtils } from '@/utils/inquiry_utils.js'
  import { useRouter } from 'vue-router'
  import { ElMessage } from 'element-plus'
  export default {
       setup(props) {
            const activeName = ref('10')
            const { formatTime, hp } = inqUtils()
            const { proxy } = getCurrentInstance()

            const param = reactive({
                 current: 1,
                 state: 10
            })

            const router = useRouter()

            const inquiryList = ref()

            const searchDTO = reactive({
                 dateRange: [],
                 state: '',
                 title: '',
                 createTimeStart: '',
                 createTimeEnd: ''
            })

            const page = reactive({
                 current: 1,
                 pageSize: 10,
                 total: 20
            })

            const confirm = () => {
                 let { dateRange, title, createTimeStart, createTimeEnd } = searchDTO
                 if (dateRange) {
                      createTimeStart = formatTime(dateRange[0])
                      createTimeEnd = formatTime(dateRange[1])
                      param.createTimeStart = createTimeStart
                      param.createTimeEnd = createTimeEnd
                 }

                 if (title) {
                      param.title = title
                 }

                 getList()
            }
            const toDelete = (inquireId) => {
               proxy.$confirm('确认删除该询盘？删除后数据不可恢复。', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
               }).then(res => {
                    proxy.$post('v3/business/front/purchaseInquire/delete', { inquireId }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              getList()
                         }
                    })
               })
          }

            const toDetail = (inquireId) => {
                 let newWin = router.resolve({
                      path: '/inquiry/purchaser/inq_info',
                      query: {
                           inquireId
                      }
                 })

                 window.open(newWin.href, '_blank')
            }

            const toEdit = (inquireId) => {
                 router.push({
                      path: '/inquiry/purchaser/init_inquiry',
                      query: {
                           inquireId,
                           from: 'Edit'
                      }
                 })
            }

            const reset = () => {
                 searchDTO.dateRange = []
                 searchDTO.title = ''
                 delete param.createTimeStart
                 delete param.createTimeEnd
                 delete param.title
                 param.current = 1
                 getList()
            }

            const handleClick = (tab) => {
                 param.current = 1
                 param.state = tab.props.name
                 getList()
            }


            const getList = () => {
                 proxy.$get('v3/business/front/purchaseInquire/list', param).then(res => {
                      if (res.state == 200) {
                         res.data.list.forEach(item=>{
                              if(!item.enterpriseName){
                                   item.enterpriseName = '--'
                              }
                              if(!item.receiverName){
                                   item.receiverName = '--'
                              }
                              if(!item.receiverMobile){
                                   item.receiverMobile = '--'
                              }
                              if(!item.description){
                                   item.description = '--'
                              }
                         })
                           inquiryList.value = res.data.list
                           page.current = res.data.pagination.current
                           page.pageSize = res.data.pagination.pageSize
                           page.total = res.data.pagination.pageSize
                      }
                 })
            }


            const init_inquiry = () => {
               //   router.push('/inquiry/purchaser/init_inquiry')
                 router.push('/inquiry/present')
            }

            const titleFormatter = (row, column, cellValue, index) => {
                 return cellValue.length > 10 ? cellValue.slice(0, 10) + '...' : cellValue
            }

            const toxia = (files)=>{
               if(files.length>0&&files!=null){
                    window.open(files, '_blank')
               }else{
                    ElMessage('无需求单要下载')
               }
            }

            onMounted(() => {
                 getList()
            })

            return {
                 activeName,
                 handleClick,
                 page,
                 ...toRefs(searchDTO),
                 inquiryList,
                 init_inquiry,
                 toDetail,
                 toEdit,
                 confirm,
                 reset,
                 titleFormatter,
                 hp,
                 toDelete,
                 toxia
            }
       }
  }


</script>

<style lang="scss">
  input {
       height: 36px;
       outline: none;
       border: none;

  }

  .my_inquiry {
       .is-active {
            color: $colorMain;
       }

       .el-tabs__active-bar {
            background-color: $colorMain;
       }

       .el-tabs__item:hover {
            color: $colorMain;
            cursor: pointer;
       }

       .el-date-editor--daterange.el-input__inner {
            width: 250px;
       }

       .el-date-editor .el-range-separator {
            width: 7%;
       }

       .wrap6 .el-input__inner {
            border: none;
       }

       .has-gutter tr th {
            background: #F7F8FA;
            color: #101010;
       }
  }
</style>

<style lang="scss" scoped>
  .my_inquiry {
       float: left;
       width: 1015px;
       background-color: #fff;
       margin-left: 15px;
       padding: 20px;
       position: relative;

       .title {
            font-size: 16px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            font-weight: bold;
       }

       .nav_bar {
            margin-top: 20px;
       }


       .wrap2 {
            background-color: rgba(40, 95, 222, 1);
            border-radius: 4px;
            height: 36px;
            width: 120px;
            position: absolute;
            right: 20px;
            top: 11px;
            cursor: pointer;

            .word3 {
                 color: rgba(255, 255, 255, 1);
                 font-size: 16px;
                 line-height: 16px;
                 display: block;
                 margin: 10px 0 0 28px;
            }
       }

       .box1 {
            margin-top: 20px;

            .info1 {
                 white-space: nowrap;
                 color: rgba(51, 51, 51, 1);
                 font-size: 14px;
                 font-family: SourceHanSansCN-Regular;
            }

            .wrap3 {
                 background-color: rgba(255, 255, 255, 1);
                 width: 241px;
                 height: 36px;
                 border: 1px solid rgba(201, 201, 201, 1);
                 margin-left: 10px;
                 padding: 0 10px;
            }

            .word3 {
                 white-space: nowrap;
                 color: rgba(51, 51, 51, 1);
                 font-size: 14px;
                 font-family: SourceHanSansCN-Regular;
                 margin: 0px 0 0 22px;
            }

            .wrap4 {
                 background-color: rgba(255, 255, 255, 1);
                 height: 36px;
                 border: 1px solid rgba(201, 201, 201, 1);
                 margin-left: 15px;
                 width: 115px;

                 .bd3 {
                      width: 95px;
                      height: 18px;
                      margin: 8px 0 0 10px;

                      .word4 {
                           width: 49px;
                           height: 12px;

                           color: rgba(153, 153, 153, 1);
                           font-size: 12px;
                           font-family: SourceHanSansCN-Regular;
                           text-align: left;
                           white-space: nowrap;
                           line-height: 12px;
                           margin-top: 4px;
                           display: block;
                      }

                      .icon1 {
                           width: 18px;
                           height: 18px;
                      }
                 }
            }

            .word5 {
                 width: 8px;

                 color: rgba(153, 153, 153, 1);
                 font-size: 14px;
                 font-family: SourceHanSansCN-Regular;
                 text-align: left;
                 white-space: nowrap;
                 line-height: 14px;
                 display: block;
                 margin: 17px 0 0 19px;
            }

            .wrap5 {
                 background-color: rgba(255, 255, 255, 1);
                 height: 36px;
                 border: 1px solid rgba(201, 201, 201, 1);
                 margin-left: 17px;
                 width: 115px;

                 .section1 {
                      width: 96px;
                      height: 18px;
                      margin: 9px 0 0 11px;

                      .info2 {
                           width: 49px;
                           height: 12px;

                           color: rgba(153, 153, 153, 1);
                           font-size: 12px;
                           font-family: SourceHanSansCN-Regular;
                           text-align: left;
                           white-space: nowrap;
                           line-height: 12px;
                           margin-top: 2px;
                           display: block;
                      }

                      .label3 {
                           width: 18px;
                           height: 18px;
                      }
                 }
            }

            .txt1 {
                 color: rgba(51, 51, 51, 1);
                 font-size: 14px;
                 display: block;
                 margin: 0px 0 0 28px;
            }

            .wrap6 {
                 background-color: rgba(255, 255, 255, 1);
                 height: 36px;
                 border: 1px solid rgba(201, 201, 201, 1);
                 width: 100px;
                 margin: 0px 0 0 11px;

                 .layer3 {
                      width: 85px;
                      height: 11px;
                      margin: 12px 0 0 8px;

                      .txt2 {
                           width: 37px;
                           height: 11px;

                           color: rgba(153, 153, 153, 1);
                           font-size: 12px;
                           font-family: SourceHanSansCN-Regular;
                           text-align: left;
                           white-space: nowrap;
                           line-height: 12px;
                           display: block;
                      }

                      .label4 {
                           width: 13px;
                           height: 7px;
                           margin-top: 3px;
                      }
                 }
            }

            .wrap7 {
                 background-color: rgba(40, 95, 222, 1);
                 border-radius: 4px;
                 height: 32px;
                 width: 68px;
                 margin: 3px 0 0 31px;
                 cursor: pointer;

                 .info3 {
                      width: 28px;
                      height: 13px;

                      color: rgba(255, 255, 255, 1);
                      font-size: 14px;
                      font-family: SourceHanSansCN-Regular;
                      text-align: left;
                      white-space: nowrap;
                      line-height: 14px;
                      display: block;
                      margin: 9px 0 0 19px;
                 }
            }

            .wrap8 {
                 border-radius: 4px;
                 height: 32px;
                 border: 1px solid rgba(40, 95, 222, 1);
                 width: 68px;
                 margin: 3px 0 0 20px;
                 cursor: pointer;

                 .info4 {
                      width: 29px;
                      height: 13px;

                      color: rgba(40, 95, 222, 1);
                      font-size: 14px;
                      font-family: SourceHanSansCN-Regular;
                      text-align: left;
                      white-space: nowrap;
                      line-height: 14px;
                      display: block;
                      margin: 9px 0 0 19px;
                 }
            }
       }

       .box22 {
            margin-top: 30px;

            .box33 {
                 border: 1px solid #ECEBEB;

            }

            .text2 {
                 font-size: 12px;
                 font-family: Source Han Sans CN;
                 font-weight: 400;
                 color: #FA0606;
            }

            .TextGroup12 {
                 .txt78 {
                      color: rgba(51, 51, 51, 1);
                      font-size: 12px;
                      display: block;
                 }
            }


            .TextGroup1 {

                 .txt77 {
                      color: rgba(51, 51, 51, 1);
                      font-size: 12px;
                      display: block;
                      cursor: pointer;

                      &:hover {
                           color: rgba(40, 95, 222, 1);

                      }

                      &.blue {
                           color: rgba(40, 95, 222, 1) !important;
                      }
                 }

                 .main3 {
                      margin-top: 4px;

                      .txt8 {
                           color: rgba(51, 51, 51, 1);
                           font-size: 12px;
                           cursor: pointer;

                           &:hover {
                                color: rgba(40, 95, 222, 1);

                           }
                      }

                      .word15 {
                           color: rgba(51, 51, 51, 1);
                           font-size: 12px;
                           cursor: pointer;

                           &:hover {
                                color: rgba(40, 95, 222, 1);

                           }
                      }
                 }
            }
       }

       .inquiry_empty {

            padding-top: 140px;
            padding-bottom: 200px;

            img {
                 width: 120px;
                 height: 119px;
            }

            p {
                 font-size: 14px;
                 font-family: Source Han Sans CN;
                 font-weight: 400;
                 color: #333333;
                 margin-top: 34px;
            }
       }
       .demand_list{
        img{
          width: 24px;
          height: 24px;
        }
       }
  }
</style>