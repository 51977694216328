import { ref, reactive, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import areaData from "@/assets/area.json";
import { start } from 'nprogress';
export const inqUtils = () => {
     const fileDTO = reactive({
          path: '',
          url: '',
          type: ''
     })
     const { proxy } = getCurrentInstance()
     const fileList = ref([])

     const imgSuffix = ['png', 'jpeg', 'jpg']
     const docsuffix = ['pdf', 'doc', 'docx', 'xls', 'xlsx']
     const typelist = ['image/png',
          'image/jpeg',
          'image/jpg',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/pdf']

     const uploadFile = (e, cb, count) => {
          let needFiles = []
          if (fileList.value.length + e.target.files.length > count) {
               needFiles = Array.from(e.target.files).slice(0, count - fileList.value.length)
               ElMessage(`最多上传${count}个附件`)
          } else {
               needFiles = e.target.files
          }
          var fileObj = document.getElementById("upload").files[0];
          for (let i = 0; i < needFiles.length; i++) {
               let file = needFiles[i]
               let index = typelist.findIndex(i => file.type == i)
               if (index < 0) {
                    ElMessage('上传的文件格式不正确')
                    return
               }
               if (file) {
                    proxy.$post('/v3/oss/front/upload', {
                         source: 'inquire',
                         file
                    }, 'form').then(res => {
                         if (res.state == 200) {
                              if(fileObj.name){
                                   res.data.name = fileObj.name.split('.')[0]
                              }
                              fileList.value.push(setFileDTO(res.data))
                              cb()

                         } else {
                              ElMessage(res.msg)
                         }

                    })
               }
          }
     }

     const duration = ref(0)
     const timer = ref(null)
     const timeStr = reactive({
          day: '',
          hour: '',
          minute: '',
          second: ''
     })


     const countDown = () => {
          duration.value--

          let stampArr = [
               parseInt(duration.value / 60 / 60 / 24),
               parseInt(duration.value / 60 / 60 % 24),
               parseInt(duration.value / 60 % 60),
               parseInt(duration.value % 60)
          ].map(i => i > 9 ? i : '0' + i)
          timeStr.day = stampArr[0]
          timeStr.hour = stampArr[1]
          timeStr.minute = stampArr[2]
          timeStr.second = stampArr[3]
          console.log(timeStr.day, timeStr.hour, timeStr.minute, timeStr.second)

          if (duration.value == 0) {
               clearTimeout(timer.value)
          } else {
               timer.value = setTimeout(countDown, 1000)
          }
     }


     const handleCountDown = (st, et) => {
          let startTime = new Date(st).getTime()
          let endTime = new Date(et).getTime()
          duration.value = (endTime - startTime) / 1000
          countDown()
     }


     const uploadFileSingle = (e, cb) => {
          let file = e.target.files[0]
          if (file) {
               proxy.$post('/v3/oss/front/upload', {
                    source: 'inquire',
                    file
               }, 'form').then(res => {
                    if (res.state == 200) {
                         cb(setFileDTO(res.data))
                    } else {
                         ElMessage(res.msg)
                    }

               })
          }
     }


     const downFile = (file) => {
          if (file.suffix == 'doc') {
               let fileName = '文档'
               const aLink = document.createElement('a');
               document.body.appendChild(aLink);
               aLink.style.display = 'none';
               aLink.href = file.url
               aLink.setAttribute('download', fileName);
               aLink.click();
               document.body.removeChild(aLink);
          } else {
               window.open(file.url, '_blank')
          }
     }

     const setFileDTO = ({ path, url,name }) => {
          let suffix = path.substring(path.lastIndexOf('.') + 1)
          let type
          if (imgSuffix.includes(suffix)) type = 'img'
          if (docsuffix.includes(suffix)) type = docsuffix.find(i => i == suffix)

          return { url, path, suffix, type, name}
     }


     //清除单独文件
     const clearFileList = (index) => {
          fileList.value.splice(index, 1)
     }


     const unitList = ref(['克', '千克', '吨', '件', '支', '张', '平方米', '箱', '毫升', '升', '袋', '其他'])

     const formatTime = (val) => {

          let year = new Date(val).getFullYear()
          let month = new Date(val).getMonth() + 1
          let day = new Date(val).getDate()
          let hour = new Date(val).getHours()
          let minute = new Date(val).getMinutes()
          let second = new Date(val).getSeconds()

          let date = [year, month, day].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
          let time = [hour, minute, second].map(item => item.toString().length >= 2 ? item : '0' + item).join(':')

          return date + ' ' + time
     }


     const areaDataArray = areaData
     const cascaderProps = { label: "regionName", value: "regionName" };
     const cascaderProps2 = { label: "regionName", value: "regionCode", children: "children" }

     const getPartNumber = (number, type) => {
          let target = '';
          if (number == undefined) {
               return false;
          }

          number = (number).toFixed(2).toString();
          if (type == 'int') {
               target = number.split('.')[0];
          } else if (type == 'decimal') {
               target = number.split('.')[1] != undefined ? ('.' + number.split('.')[1]) : '.00';
               if (target.length < 3) {
                    target += '0';
               }
          }
          return target;
     }


     const hp = (price) => {
          let int = price.toString().split('.')[0]

          if (int.length < 5) {
               int = Number(int).toFixed(2)
          } else if (int.length >= 5 && int.length <= 8) {
               int = int.slice(0, int.length - 4) + '万'
          } else if (int.length > 8) {
               int = int.slice(0, int.length - 8) + '亿'
          }

          return int
     }

     const disabledDate = (time) => {
          return (time.getTime() < Date.now() - 24 * 3600 * 1000)
     }

     return {
          uploadFile,
          fileList,
          clearFileList,
          unitList,
          formatTime,
          setFileDTO,
          downFile,
          uploadFileSingle,
          areaDataArray,
          cascaderProps,
          getPartNumber,
          countDown,
          duration,
          handleCountDown,
          timeStr,
          hp,
          disabledDate,
          cascaderProps2
     }



}

